import "./style.css";
import React, { useEffect } from "react";
import { useAppState, useQuery } from "../AppContext";
import { changeMessageDelay } from "../layout/actions";
import { changeInfoTela } from "../layout/actions";
import { changeFormData } from "./actions";
import { retrieveUserReliabilities, retrieveUserReliabilitiesByClient } from "./actions";
import Gold from "./levels/Gold";
import Silver from "./levels/Silver";
import Bronze from "./levels/Bronze";
import MyLevel from "./levels/MyLevel";
import MyReliabilities from "./levels/MyReliabilities";

const componentDidMount = (
  dispatch,
  state,
  qpClientId,
  qpRel,
  qpCategories,
  qpLevels
) => () => {
  dispatch(changeInfoTela({ title: 'Nível da conta', breadcrumbs: [{ label: 'Nível da conta' }] }))
  if (qpClientId) {
    dispatch(
      retrieveUserReliabilitiesByClient(
        qpClientId,
        qpRel,
        qpCategories,
        qpLevels
      )
    );
    sessionStorage.setItem("clientUrlReliability", window.location.href);
  } else {
    if (sessionStorage.getItem("clientUrlReliability")) {
      window.location.href = sessionStorage.getItem("clientUrlReliability");
    } else {
      dispatch(retrieveUserReliabilities());
    }
  }
};

function Home() {
  const [state, dispatch] = useAppState();
  const [{ reliabilities }] = useAppState();
  const lvls = reliabilities.reliabilities;

  const paramObj = useQuery();
  const qpReliabilities = paramObj.get("confiabilidades");
  const qpLevels = paramObj.get("niveis");
  const qpCategories = paramObj.get("categorias");
  const qpClientId = paramObj.get("client_id");
  const lvlsId = [];
  let maxLevel = 0;

  useEffect(
    componentDidMount(
      dispatch,
      state,
      qpClientId,
      qpReliabilities,
      qpCategories,
      qpLevels
    ),
    []
  );

  const client = reliabilities.client;

  const [{ formData }] = useAppState();
  if (formData.reliabilityAcquired) {
    dispatch(changeFormData({ ...formData, reliabilityAcquired: false }));
  }

  const goToClientPage = () => {
    if (state.layout.message.type === undefined) {
      dispatch(
        changeMessageDelay({
          type: "SUCCESS",
          text:
            "Você adquiriu as confiabilidades necessárias. Aguarde, em instantes você será redirecionado de volta para " +
            client.name
        })
      );
    }
    sessionStorage.removeItem("clientUrlReliability");
    setTimeout(() => {
      window.location.href = client.homePage;
    }, 10000);
  };

  return (
    <>
      {/* Obtendo o maior nível */}
      {lvls && lvls.filter(l1 => l1.anyAcquired).map(l2 => {
        lvlsId.push(parseInt(l2.level.id))
        maxLevel = Math.max(...lvlsId)
      })}

      <span className="page-title">Nível da conta gov.br</span>
      <span className="sub-page-title">Seu nível atual</span>

      <MyLevel maxLevel={maxLevel} />
      <MyReliabilities reliabilities={reliabilities} levels={lvls} open={false} />

      <p className="sub-page-title">Aumente o seu nível</p>
      <p><b>A conta gov.br possui três níveis: ouro, prata e bronze.</b></p>
      <p>Se você está no nível <b>bronze</b> ou <b>prata</b>, migre para o nível <b>ouro</b> e aproveite todas as vantagens da conta gov.br!</p>
      <div className="card-levels">
        <Gold level={maxLevel} />
        <Silver level={maxLevel} />
        <Bronze level={maxLevel} />
      </div>

      <a id="info" href="https://www.gov.br/governodigital/pt-br/conta-gov-br/saiba-mais-sobre-os-niveis-da-conta-govbr/saiba-mais-sobre-os-niveis-da-conta-govbr">
        <i className="fa fa-question-circle-o" aria-hidden="true"></i>
        Saiba mais sobre os níveis ouro, prata e bronze.
      </a>

      {reliabilities.redirect && goToClientPage()}
    </>
  );
}

export default Home;
